import React from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"




const LSExperimental = () => {
  //javascript
  const title = 'As Built Piers';
  const metatitle = 'As Built Piers';
  const description = 'Meta Description for As Built Piers';
  const metadescription = description;

  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more" href="cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      
      
    </Layout>
  )
}

export default LSExperimental
